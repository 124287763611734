


















import {Component} from 'vue-property-decorator';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import moment from 'moment';
import {vxm} from '@/store';
import {PaymentHistoryInterface} from '@/types/PaymentHistoryInterface';
import {EarningTypesEnum} from '@/types/enums/EarningTypesEnum';
import {mixins} from 'vue-class-component';
import NumbersMixin from '@/mixins/NumbersMixin';

@Component
export default class PaymentHistory extends mixins(MomentDateMixin, NumbersMixin) {
  data = []; // all months
  filteredData = {}; // month
  earningTypes = EarningTypesEnum;
  currentDate = moment();

  get filterData() {
    return this.filteredData;
  }

  set filterData(newVal) {
    this.filteredData = this.filteredMonth(newVal);
  }

  async created() {
    this.data = await this.loadHistory();
    this.filterData = this.data;
  }

  goToNextMonth(val: number) {
    this.currentDate = this.currentDate.add(val, 'month');
    this.filterData = this.data;
  }
  //TODO create API request for 3 month forward and back, then if user go to the and, create new request
  loadHistory() {
    return vxm.fan
      .ordersHistory()
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }

  filteredMonth(data): PaymentHistoryInterface | PaymentHistoryInterface[] {
    return data.filter(
      (el: PaymentHistoryInterface) => moment(el.updatedAt).format('M/YYYY') === this.currentDate.format('M/YYYY'),
    );
  }

  formattingDate(date: string): string {
    return moment(date).format('MM/DD/YY');
  }

  formatSubType(data: string) {
    if (data === this.earningTypes.SALE_VIDEO) {
      return 'video_purchase';
    }
    return data.toLowerCase();
  }
}
